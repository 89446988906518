/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Menu, Icon, Badge, Avatar, Tooltip, Popover } from "antd";
import axios from "axios";
import { USER_SERVER } from "../../../Config";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ShareButton from "../../../utils/ShareButton/ShareButton";

function RightMenu(props) {
  const user = useSelector((state) => state.user);

  const { t, i18n } = useTranslation();

  const languagesMenu = (
    <div>
      <button onClick={() => handleClick("en")}>English</button>
      <button onClick={() => handleClick("fr")}>Français</button>
      <button onClick={() => handleClick("ar")}>العربية</button>
    </div>
  );

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
  };

  const logoutHandler = () => {
    axios.get(`${USER_SERVER}/logout`).then((response) => {
      if (response.status === 200) {
        props.history.push("/login");
        localStorage.removeItem("hasSeenDiscount");
      } else {
        alert("Log Out Failed");
      }
    });
  };

  if (user.userData && !user.userData.isAuth) {
    return (
      <Menu mode={props.mode}>
        <Menu.Item key="mail">
          <a href="/login">{t("navbar.singin")}</a>
        </Menu.Item>
        <Menu.Item key="app">
          <a href="/register">{t("navbar.signup")}</a>
        </Menu.Item>
        {props.mode === "inline" && (
          <Menu.Item key="aboutUs">
            <a href="/about-us">
              <Icon type="read" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("navbar.aboutUs")}
            </a>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="faq">
            <a href="/faq">
              <Icon type="question-circle" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("footer.faq")}
            </a>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="pricing">
            <a href="/pricing">
              <Icon type="dollar" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("footer.pricing")}
            </a>
          </Menu.Item>
        )} 
        {props.mode === "inline" && (
          <Menu.Item key="languages">
            <Popover placement="top" content={languagesMenu} trigger="click">
              <Icon type="global" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("navbar.langs")}
            </Popover>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="terms and conditions">
            <a href="/terms-and-conditions">{t("footer.terms")}</a>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="privacy policy">
            <a href="/privacy-policy">{t("footer.privacy")}</a>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="rightReserved">
            <p style={{ fontSize: "10px" }}>
              © {new Date().getFullYear()} {t("footer.companyName")}.{" "}
              {t("footer.rightsReserved")}
            </p>
          </Menu.Item>
        )}
      </Menu>
    );
  }
  if (user.userData && user.userData.isAuth && user.userData.isAdmin) {
    return (
      <Menu mode={props.mode}>
        <Menu.Item key="Dashboard">
          <Badge>
            <Tooltip placement="bottom" title={t("navbar.tipDash")}>
              <a
                href="/dashboard"
                style={{ marginRight: -22, color: "#66777" }}
              >
                <Icon
                  type="dashboard"
                  style={{ fontSize: 30, marginBottom: 4 }}
                />
                {props.mode === "inline" && <span>{t("navbar.dash")}</span>}
              </a>
            </Tooltip>
          </Badge>
        </Menu.Item>

        {props.mode === "inline" && (
          <Menu.Item key="logout">
            <a onClick={logoutHandler}>
              <Icon type="logout" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("navbar.logout")}
            </a>
          </Menu.Item>
        )}
      </Menu>
    );
  } else {
    return (
      <Menu mode={props.mode}>
        {props.mode === "inline" && (
          <Menu.Item key="history">
            <a href="/history">
              <Icon type="history" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("navbar.history")}
            </a>
          </Menu.Item>
        )}

        <Menu.Item key="upload" className="other-items upload">
          <Badge>
            <Tooltip placement="bottom" title={t("navbar.tipUpPro")}>
              <a
                href="/product/upload"
                style={{ marginRight: -22, color: "#66777" }}
              >
                <Icon type="upload" style={{ fontSize: 30, marginBottom: 4 }} />
                {props.mode === "inline" && <span>{t("navbar.upProd")}</span>}
              </a>
            </Tooltip>
          </Badge>
        </Menu.Item>
        {/* upload delivery link (add to next version)*/}
        {/*           <Menu.Item key="uploadDelivery">
            <a href="/delivery/upload">Upload Delivery</a>
          </Menu.Item> */}
        {/* find deliveries (add to next version) */}
        {/*           <Menu.Item key="Delivery">
            <a href="/delivery">Delivery</a>
          </Menu.Item> */}
        <Menu.Item
          key="deliveryProfile"
          className="other-items deliveryProfile"
        >
          {/* we display only the accepted deliveries by the customer */}
          <Badge
            count={
              user.userData &&
              user.userData.deliveryOrders.filter(
                (delivery) => delivery.acceptDelivery
              ).length
            }
            dot={props.mode === "inline"}
          >
            <Tooltip placement="bottom" title={t("navbar.tipDeliveryOrders")}>
              <a
                href="/deliveryProfile"
                style={{ marginRight: -22, color: "#66777" }}
              >
                <Icon type="car" style={{ fontSize: 30, marginBottom: 4 }} />
                {props.mode === "inline" && (
                  <span>
                    {t("navbar.deliveryOrders")}{" "}
                    {`(${
                      user.userData && user.userData.deliveryOrders.length
                    })`}
                  </span>
                )}
              </a>
            </Tooltip>
          </Badge>
        </Menu.Item>

        <Menu.Item key="cart" className="cart">
          <Badge
            count={user.userData && user.userData.cart.length}
            dot={props.mode === "inline"}
          >
            <Tooltip placement="bottom" title={t("navbar.tipShCart")}>
              <a
                href="/user/cart"
                style={{ marginRight: -22, color: "#66777" }}
              >
                <Icon className="my-shopping-cart-icon" type="shopping-cart" />
                {props.mode === "inline" && (
                  <span>
                    {t("navbar.shCart")}{" "}
                    {`(${user.userData && user.userData.cart.length})`}
                  </span>
                )}
              </a>
            </Tooltip>
          </Badge>
        </Menu.Item>

        <Menu.Item key="orders" className="other-items orders">
          <Badge
            count={user.userData && user.userData.orders.length}
            dot={props.mode === "inline"}
          >
            <Tooltip placement="bottom" title={t("navbar.tipOrder")}>
              <a href="/orders" style={{ marginRight: -22, color: "#66777" }}>
                <Icon type="shop" style={{ fontSize: 30, marginBottom: 4 }} />
                {props.mode === "inline" && (
                  <span>
                    {t("navbar.order")}{" "}
                    {`(${user.userData && user.userData.orders.length})`}
                  </span>
                )}
              </a>
            </Tooltip>
          </Badge>
        </Menu.Item>

        <Menu.Item key="notifications" className="other-items notifications">
          <Badge
            count={user.userData && user.userData.notifications.length}
            dot={props.mode === "inline"}
          >
            <Tooltip placement="bottom" title={t("navbar.notfi")}>
              <a
                href="/notifications"
                style={{ marginRight: -22, color: "#66777" }}
              >
                <Icon type="bell" style={{ fontSize: 30, marginBottom: 4 }} />
                {props.mode === "inline" && (
                  <span>
                    {t("navbar.notfi")}{" "}
                    {`(${user.userData && user.userData.notifications.length})`}{" "}
                  </span>
                )}
              </a>
            </Tooltip>
          </Badge>
        </Menu.Item>

        <Menu.Item key="deliveryDates" className="other-items deliveryDates">
          <Badge
            count={user.userData && user.userData.deliveryDates.length}
            dot={props.mode === "inline"}
          >
            <Tooltip placement="bottom" title={t("navbar.delDate")}>
              <a
                href="/deliveryDates"
                style={{ marginRight: -22, color: "#66777" }}
              >
                <Icon
                  type="calendar"
                  style={{ fontSize: 30, marginBottom: 4 }}
                />
                {props.mode === "inline" && (
                  <span>
                    {t("navbar.delDate")}{" "}
                    {`(${user.userData && user.userData.deliveryDates.length})`}
                  </span>
                )}
              </a>
            </Tooltip>
          </Badge>
        </Menu.Item>

        <Menu.Item key="profile" className="other-items profile">
          <Tooltip
            placement="bottom"
            title={user.userData && user.userData.name}
          >
            <a href="/profile">
              <Avatar src={user.userData && user.userData.image} />
              {props.mode === "inline" && <span>{t("navbar.mProfile")}</span>}
            </a>
          </Tooltip>
        </Menu.Item>

        {props.mode === "inline" && (
          <Menu.Item key="languages">
            <Popover placement="top" content={languagesMenu} trigger="click">
              <Icon type="global" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("navbar.langs")}
            </Popover>
          </Menu.Item>
        )}

        {props.mode === "inline" && (
          <Menu.Item key="Share">
            <Popover
              placement="top"
              title={t("shareBtn.shareBtnMsg")}
              content={
                <ShareButton
                  shareId={user.userData && user.userData.shareId}
                  home={true}
                />
              }
              trigger="click"
            >
              <Tooltip placement="bottom" title={t("navbar.shareMsgTooltip")}>
                <Icon
                  type="share-alt"
                  style={{ fontSize: 30, marginBottom: 4 }}
                />
                {t("navbar.share")}
              </Tooltip>
            </Popover>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="aboutUs">
            <a href="/about-us">
              <Icon type="read" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("navbar.aboutUs")}
            </a>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="faq">
            <a href="/faq">
              <Icon type="question-circle" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("footer.faq")}
            </a>
          </Menu.Item>
        )}

        {props.mode === "inline" && (
          <Menu.Item key="pricing">
            <a href="/pricing">
              <Icon type="credit-card" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("footer.pricing")}
            </a>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="contact us">
            <a href="/contact">
              <Icon type="phone" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("footer.contactUs")}
            </a>
          </Menu.Item>
        )}
        {/* to add in the next version */}
        {/* {props.mode === "inline" && (
          <Menu.Item key="Advertisement">
            <a href="/advertisement/upload">
              <Icon
                type="notification"
                style={{ fontSize: 30, marginBottom: 4 }}
              />
              {t("navbar.ad")}
            </a>
          </Menu.Item>
        )} */}

        {props.mode === "inline" && (
          <Menu.Item key="logout">
            <a onClick={logoutHandler}>
              <Icon type="logout" style={{ fontSize: 30, marginBottom: 4 }} />
              {t("navbar.logout")}
            </a>
          </Menu.Item>
        )}

        {props.mode === "inline" && (
          <Menu.Item key="terms and conditions">
            <a href="/terms-and-conditions">{t("footer.terms")}</a>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="privacy policy">
            <a href="/privacy-policy">{t("footer.privacy")}</a>
          </Menu.Item>
        )}
        {props.mode === "inline" && (
          <Menu.Item key="rightReserved">
            <p style={{ fontSize: "10px" }}>
              © {new Date().getFullYear()} {t("footer.companyName")}.{" "}
              {t("footer.rightsReserved")}
            </p>
          </Menu.Item>
        )}
      </Menu>
    );
  }
}

export default withRouter(RightMenu);
