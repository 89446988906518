import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * @description PaymentGatewayRedirect component is used to redirect to the payment success page
 * @component
 * @returns {JSX.Element} - Rendered PaymentGatewayRedirect component
 * */
const PaymentGatewayRedirect = () => {
  const history = useHistory();
  const paymentType = localStorage.getItem("paymentType");

  useEffect(() => {
    const redirectToPaymentSuccess = () => {
      if (paymentType === "listingFee") {
        history.replace("/payment-success");
        return;
      }
      if (paymentType === "listingFeeRenewal") {
        history.replace("/listing-fee-renewal-success");
        return;
      }
      if (paymentType === "subscription") {
        history.replace("/subscription-success");
        return;
      }

      if (paymentType === "subscriptionRenewal") {
        history.replace("/subscription-renewal-success");
        return;
      }
    };

    redirectToPaymentSuccess();
  }, [history]);

  return <div>Redirecting...</div>;
};

export default PaymentGatewayRedirect;
